<template>
  <div>
    <Navlid :list="navlist"></Navlid>
    <div class="public-box">
      <el-form :inline="true">
        <el-form-item>
          <el-select
            v-model="form.contract_fee_id"
            clearable
            class="offset"
            placeholder="费用类型"
          >
            <el-option
              v-for="(item, index) of feeList"
              :key="index"
              :label="item.text"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="form.is_abnormal"
            clearable
            class="offset"
            placeholder="用量状态"
          >
            <el-option label="正常" :value="0"></el-option>
            <el-option label="异常" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <MyButton @click="getList" class="offset">
            <span>搜索</span>
            <template slot="preImage">
              <img src="../../../unit/img/search.png" alt />
            </template>
          </MyButton>
        </el-form-item>
        <el-form-item>
          <MyButton class="offset" :accessId="60777" @click="chargeoffExport">
            <span>导出</span>
            <template slot="preImage">
              <img src="../../../unit/img/down.png" alt />
            </template>
          </MyButton>
        </el-form-item>
        <el-form-item>
          <MyButton class="offset" :accessId="83230" @click="set">
            <span>设置</span>
            <template slot="preImage">
              <img src="../../../unit/img/st.png" alt />
            </template>
          </MyButton>
        </el-form-item>
        <el-form-item>
          <MyButton
            class="offset"
            :accessId="11096"
            @click="hisconfirmvisible = true"
            ><template slot="preImage">
              <img src="../../../assets/img/his.png" alt />
            </template>
            <span>历史确认信息</span>
          </MyButton>
        </el-form-item>
        <!-- <el-form-item>
          <MyButton
            class="offset"

            @click="hisconfirmvisible = true"
          >
            <span>历史确认信息</span>
          </MyButton>
        </el-form-item> -->
        <el-form-item>
          <MyButton
            class="offset"
            :accessId="43836"
            @click="batchConfirmation(0)"
            ><template slot="preImage">
              <img src="../../../assets/img/ss.png" alt />
            </template>
            <span>批量确认</span>
          </MyButton>
        </el-form-item>
        <el-form-item>
          <MyButton
            class="offset"
            :accessId="32205"
            @click="batchConfirmation(1)"
          >
            <template slot="preImage">
              <img src="@/assets/img/b2.png" alt />
            </template>
            <span>全部出账</span>
          </MyButton>
        </el-form-item>
      </el-form>
      <div class="public-table">
        <el-table
          :data="datalist"
          :span-method="objectSpanMethod"
          :row-class-name="tableRowClassName"
          @selection-change="handleSelectionChange"
          :header-cell-style="{
            'text-align': 'center',
            background: 'rgb(245, 245, 245)',
          }"
          :cell-style="{ 'text-align': 'center' }"
          v-loading="loading"
        >
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column
            label="商户姓名"
            prop="contact_name"
          ></el-table-column>
          <el-table-column label="出账时间" prop="create_at"></el-table-column>
          <el-table-column label="费用类型" prop="bill_name"></el-table-column>
          <el-table-column
            label="前三期平均使用量"
            prop="three_price"
          ></el-table-column>
          <el-table-column
            label="本期使用量"
            prop="now_key_number"
          ></el-table-column>
          <el-table-column label="用量状态" prop="is_abnormal">
            <template slot-scope="{ row }">{{
              row.is_abnormal == 0 ? "正常" : "异常"
            }}</template>
          </el-table-column>
          <el-table-column label="确认状态">
            <template slot-scope="{ row }">
              <span v-if="row.bill_status == 0">默认</span>
              <span v-else-if="row.bill_status == -1">待确认</span>
              <span v-if="row.bill_status == 10">交费</span>
              <span v-else-if="row.bill_status == -2">未录入</span>
            </template>
          </el-table-column>
          <!--        <el-table-column label="操作人" prop="worker_name"></el-table-column>-->
          <!--        <el-table-column label="操作时间" prop="update_at"></el-table-column>-->
          <el-table-column label="操作">
            <template slot-scope="{ row }">
              <div class="slot-box">
                <pop
                  tips="确认出账"
                  popRight
                  @myclick="confirmchargeoff(row)"
                  v-if="row.bill_status == -1"
                >
                  <img src="@/assets/img/icon/quer.png" class="icon" alt />
                </pop>
                <!-- <pop
                  tips="详情"

                  @myclick="gourl(`daybilldetail?contract_id=${row.contract_id}&merchants_id=${row.merchants_id}`)"
                >
                  <img src="@/assets/img/icon/xiangqing.png" class="icon" alt />
                </pop> -->
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="public-page">
        <el-pagination
          :total="total"
          :current-page="form.page"
          :page-size="form.limit"
          layout="total,prev,pager,next"
          next-text="下一页"
          prev-text="上一页"
          @current-change="getList"
        ></el-pagination>
      </div>
    </div>

    <!-- 历史确认信息 -->
    <el-dialog
      title="历史确认信息"
      width="70%"
      :visible.sync="hisconfirmvisible"
      :close-on-click-modal="false"
    >
      <hisconfirm v-if="hisconfirmvisible"></hisconfirm>
    </el-dialog>
    <!-- 设置 -->
    <rConfirm type="slot" ref="setForm" @confirm="confirmset">
      <template slot="content">
        <div class="set-box">
          <div>
            <span>本期产生费用>前三期平均费用</span>
            <span>
              <el-input
                style="width: 132px"
                type="number"
                v-num
                v-model.number="setForm.values.dosage_multiple"
                placeholder
              ></el-input>
            </span>
            <span>倍提示异常</span>
          </div>
          <div style="margin-top: 20px">
            <span>超过确认日期自动确认:</span>
            <el-switch
              v-model="setForm.values.auto_confirm"
              :active-value="1"
              :inactive-value="0"
              inactive-text="关闭"
              active-text="开启"
            ></el-switch>
          </div>
        </div>
      </template>
    </rConfirm>
  </div>
</template>
<script>
import hisconfirm from "./chargeoffchild/hisconfirm.vue";
import rConfirm from "../../../unit/components/confirm.vue";

export default {
  components: {
    hisconfirm,
    rConfirm,
  },
  data() {
    return {
      navlist: [
        {
          title: "商户账单",
          url: "/charge/merchantbill/basebill",
        },
        {
          title: "日常账单",
          url: "/charge/merchantbill/daybill",
        },
        {
          title: "出账记录",
        },
      ],
      form: {
        merchants_name: "",
        contract_fee_id: "",
        is_abnormal: "",
        confirm_status: "", //确认状态 0未确认，1已确认
        page: 1,
        limit: 10,
      },
      setForm: {
        key: "chargeOff",
        describe: "出账设置",
        values: {
          dosage_multiple: 0,
          auto_confirm: 0,
        },
      },
      total: 0,
      datalist: [],
      hisconfirmvisible: false,
      bill_id: [],
      arrLength: [],
      loading: false,
      feeList: [],
    };
  },
  created() {
    this.$parent.navshow = false;
    this.getList();
    this.getFeeList();
  },
  methods: {
    getFeeList() {
      this.$request.HttpGet("/setting/dailySetting").then((res) => {
        this.feeList =
          res.data?.values_struct?.map((item) => ({
            id: item.children.property_setting_id,
            text: item.type_name,
          })) ?? [];
      });
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if ([0, 1, 8].includes(columnIndex)) {
        if (row.rowspan > 0) {
          return [row.rowspan, 1];
        }
        return [0, 0];
      }
    },
    chargeoffExport() {
      this.$exported(
        "/bill/dailyOutConfirmExport",
        this.form,
        "日常账单出账记录.xlsx"
      );
    },
    gourl(url) {
      this.$router.push(url);
    },
    handleSelectionChange(e) {
      console.log(e);
      this.bill_id = e;
    },
    batchConfirmation(type) {
      if (this.bill_id.length <= 0 && type == 0) {
        this.$common.notifyError("请先选择");
        return false;
      }
      let arr = [];
      this.bill_id.forEach((item) => {
        arr = arr.concat(item.allIds);
      });

      this.$myconfirm("请确认信息是否核对完成").then(() => {
        this.$request
          .HttpPost("/bill/dailyOutAccountConfirm", {
            bill_id: arr,
            whether_all: type,
          })
          .then((res) => {
            this.$common.notifySuccess(res.msg);
            this.getList();
          });
      });
    },
    confirmchargeoff(row) {
      this.$myconfirm("请确认信息是否核对完成").then(() => {
        this.$request
          .HttpPost("/bill/dailyOutAccountConfirm", {
            bill_id: row.allIds,
          })
          .then((res) => {
            this.$common.notifySuccess(res.msg);
            this.getList();
          });
      });
    },
    getList(e = 1) {
      this.loading = true;
      this.form.page = e;
      this.$request
        .HttpGet("/bill/dailyOutAccountList", this.form)
        .then((res) => {
          this.loading = false;
          this.total = res.data.total;
          let value = [];
          if (res.data && res.data.list) {
            let arr = res.data.list;
            arr.forEach((item) => {
              item.allIds = [];
              if (item.daily_list) {
                item.daily_list.forEach((items, indexs) => {
                  item.allIds.push(...items.bill_ids);
                  if (indexs == 0) {
                    value.push({
                      ...item,
                      ...items,
                      rowspan: item.daily_list.length,
                    });
                  } else {
                    value.push({
                      ...item,
                      ...items,
                      rowspan: 0,
                      allIds: item.bill_ids,
                    });
                  }
                });
              } else {
                value.push({ ...item, rowspan: 1 });
              }
            });
          }
          this.datalist = value || [];
        })
        .catch(() => {
          this.loading = false;
        });
    },
    set() {
      this.$request
        .HttpGet("/setting/edit", {
          key: "chargeOff",
        })
        .then((res) => {
          if (res.data) {
            this.setForm = res.data;
          }
        });
      this.$refs.setForm.fade();
    },
    confirmset() {
      this.$request.HttpPost("/setting/add", this.setForm).then(() => {
        this.$common.notifySuccess("操作成功", "success");
      });
    },
    // 标红显示
    tableRowClassName({ row, rowIndex }) {
      if (row.is_abnormal == 1) {
        return "errors";
      }
    },
  },
};
</script>
<style lang="scss">
.errors {
  color: red;
}

.other {
  color: green;
}
</style>
