<template>
  <div>
    <div class="public-inline" style="margin-bottom: 15px">
      <!--    <MyInput placeholder="请输入商铺号" v-model="form.merchants_stall_number">-->
      <!--      <template slot="pre">商铺号:</template>-->
      <!--    </MyInput>-->
      <el-select
        v-model="form.bill_type"
        clearable
        class="offset"
        placeholder="费用类型"
      >
        <el-option
          :label="item.text"
          :value="item.id"
          v-for="(item, index) of feeList"
          :key="index"
        ></el-option>
      </el-select>
      <el-date-picker
        class="offset"
        format="yyyy-MM"
        type="month"
        placeholder="出账月份"
        value-format="yyyy-MM"
        v-model="form.search_date"
      ></el-date-picker>
      <el-select
        v-model="form.is_abnormal"
        clearable
        class="offset"
        placeholder="用量状态"
      >
        <el-option label="正常" :value="0"></el-option>
        <el-option label="异常" :value="1"></el-option>
      </el-select>
      <el-select
        class="offset"
        v-model="form.search_worker_id"
        placeholder="请选择操作人"
        clearable
      >
        <el-option
          v-for="item in workerList"
          :value="item.user_id"
          :key="item.user_id"
          :label="item.name"
        ></el-option>
      </el-select>
      <MyButton class="offset" @click="search(1)">
        <span>搜索</span>
        <template slot="preImage">
          <img src="../../../../unit/img/search.png" alt="" />
        </template>
      </MyButton>
    </div>
    <el-table
      max-height="450"
      :data="tableList"
      :span-method="objectSpanMethod"
      :header-cell-style="{
        'text-align': 'center',
        background: 'rgb(245, 245, 245)',
      }"
      :cell-style="{ 'text-align': 'center' }"
    >
      <el-table-column label="商户姓名" prop="contact_name"></el-table-column>
      <!--    <el-table-column label="出账时间" prop="merchants_id"></el-table-column>-->
      <el-table-column label="出账时间" prop="confirm_time"></el-table-column>
      <el-table-column label="费用类型" prop="bill_name"></el-table-column>
      <el-table-column
        label="前三期平均使用量"
        prop="three_number"
      ></el-table-column>
      <el-table-column
        label="本期使用量"
        prop="now_key_number"
      ></el-table-column>
      <el-table-column label="用量状态" prop="is_abnormal">
        <template slot-scope="{ row }">
          {{ row.is_abnormal == 0 ? "正常" : "异常" }}
        </template>
      </el-table-column>
      <el-table-column label="确认状态">
        <template>
          <span>已确认</span>
        </template>
      </el-table-column>
      <el-table-column label="操作人" prop="worker_name"></el-table-column>
      <el-table-column label="操作时间" prop="confirm_time"></el-table-column>
    </el-table>
    <div class="apiPage">
      <el-pagination
        :total="total"
        :current-page="form.page"
        :page-size="form.limit"
        layout="total,prev,pager,next"
        next-text="下一页"
        prev-text="上一页"
        @current-change="search"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  name: "hisconfirm",
  data() {
    return {
      form: {
        page: 1,
        limit: 100,
        merchants_stall_number: "",
        bill_type: "",
        search_date: "",
        is_abnormal: "",
        search_worker_id: "",
      },
      workerList: [],
      tableList: [],
      total: 0,
      arrLength: [0],
      feeList: [],
    };
  },
  created() {
    this.getList();
    this.getWorkerList();
    this.getFeeList();
  },
  methods: {
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        if (this.arrLength.includes(rowIndex)) {
          return {
            rowspan:
              this.arrLength[this.arrLength.indexOf(rowIndex) + 1] -
              this.arrLength[this.arrLength.indexOf(rowIndex)],
            colspan: 1,
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          };
        }
      }
    },
    search(page) {
      this.form.page = page;
      this.getList();
    },
    getFeeList() {
      this.$request.HttpGet("/setting/dailySetting").then((res) => {
        this.feeList = res.data.values_struct.map((item) => ({
          text: item.type_name,
          id: item.children.property_setting_id,
        }));
      });
    },
    getList() {
      this.$request
        .HttpGet("/bill/dailyOutConfirmHistory", this.form)
        .then((res) => {
          this.arrLength = [0];
          this.tableList = res.data?.list ?? [];
          let arr = {};
          let bz = 1;
          for (let i = 0; i < this.tableList.length; i++) {
            console.log(this.tableList[i].merchants_id);
            if (arr[this.tableList[i].merchants_id]) {
              arr[this.tableList[i].merchants_id] += 1;
              bz++;
            } else {
              arr[this.tableList[i].merchants_id] = bz++;
            }
          }

          for (let value in arr) {
            this.arrLength.push(arr[value]);
          }
          this.total = res.data.total;
        });
    },
    getWorkerList() {
      this.$request.HttpGet("/worker/list").then((res) => {
        this.workerList = res.data.list;
      });
    },
  },
};
</script>
<style lang="scss"></style>
